import { version } from './version';

export const environment = {
  BASE_URL: 'https://dteguararema.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3518305',
  schema: 'pbi_dte_guararema',
  groupId: '86bd6296-6cf9-464f-9a5b-0b3e39de2b81',
  analiseGeral: 'de9a635f-6037-465b-ae2a-766626608a5f',
  analiseUsuarios: 'a1e5bb60-bd3c-44f0-9a9c-e234720d96bf',
  analiseComunicados: '903f204e-dbb9-46ad-b001-0353195b80a9',
  analiseProcurador: 'df9bafb3-8deb-4f58-aa0e-10742ff5bf06',
  analiseEventos: 'a1197313-26da-4b39-a53c-a439a6b4befd',
  analiseContribuintes: '9eac6c36-de37-4c7a-829b-2c16c1b4e5c1'
};
